import React, { useState } from "react"
import { Waypoint } from "react-waypoint"

export const WithWaypoint = Compoent => {
  const WaypointComponent = ({ effect, ...props }) => {
    const [isVisible, setIsVisible] = useState(false)
    const handleEnter = () => {
      setIsVisible(true)
    }
    return (
      <Waypoint onEnter={handleEnter}>
        <div
          style={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? "translateY(0)" : "translateY(40px)",
            transition: "opacity 1.2s ease-in-out, transform .8s ease-in-out",
          }}
          style={
            effect
              ? isVisible
                ? { ...effect.activeStyle }
                : { ...effect.inactiveStyle }
              : ""
          }
        >
          <Compoent {...props} />
        </div>
      </Waypoint>
    )
  }
  return WaypointComponent
}
