import React from "react"

export const BuildingNavigator = ({ data }) => {
  return (
    <section className="bg-transparent building-navigator  pt-5">
      <div className="container">
        <div className="row justify-content-center">
          {data.map((building, idx) => (
            <div className="card col col-24 col-sm-12 col-lg-6 pb-3 border-0" key={idx}>
              <a
                href={building.link_url}
                className="card ml-auto mr-auto border-0 item-hot-clicker"
              >
                <div className="card-img-top border-1 row align-items-center item">
                  <div className="row building-navigator-textarea">
                    <div className="col col-24 building-navigator-title">
                      <p className="mb-2 mr-auto ml-auto p-0 building-navigator-title-text">
                        {building.title}
                      </p>
                    </div>
                    <div className="col col-24 building-navigator-subtitle">
                      <p className="mb-4 p-0 building-navigator-subtitle-text">
                        {building.subtitle}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
