import React from "react"
import Img from "gatsby-image"
import ReactHtmlParser from "react-html-parser"

import { WithWaypoint } from "../../utils/HOCs"
import { getImgFluid } from "../../utils/data"

export const IntroNews = ({ data }) => {
  const handleClick = () => {
    window.location.href = data.button.link_url
  }
  return (
    <section className="intro-news">
      <div className="image-container">
        <Img
          fluid={getImgFluid(data.image)}
          alt={data.image.alt_text}
          className="side-image "
        />
      </div>
      <div className="container">
        <div className="row no-gutters p-3 p-md-0">
          <div className="col col-md-12 p-0 p-md-4 text-area">
            <div className="row">
              <h1 className="title-text">{data.title}</h1>
            </div>
            <div className="row mb-4">
              <h2 className="subtitle-text">{data.subtitle}</h2>
            </div>
            <div className="row mb-2">
              <h3 className="body1-text">
                {ReactHtmlParser(data.third_title)}
              </h3>
            </div>
            <div className="row">
              <p className="body2-text">{ReactHtmlParser(data.paragraph)}</p>
            </div>
            <div className="row btn-row mt-4 mt-md-0">
              <button
                type="button"
                className="btn btn-outline-dark more-btn"
                onClick={handleClick}
              >
                {data.button.text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export const IntroNewsWithWaypoint = WithWaypoint(IntroNews)
