import React from "react"
import Img from "gatsby-image"

import { WithWaypoint } from "../../utils/HOCs"
import { getImgFluid } from "../../utils/data"

export const IntroMoreInfo = ({ data }) => {
  return (
    <section className="intro-more-info mb-5">
      <div className="my-5 container">
        <div className="row item-list">
          {data.map((block, idx) => (
            <div className="col-24 col-sm-12 col-md-8 item active" key={idx}>
              <a href={block.link_url}>
                <div className="item-illustration">
                  <div className="text-area">
                    <p>
                      {block.title}
                      <br />
                      {block.subtitle}
                    </p>
                  </div>
                </div>
                <Img
                  fluid={getImgFluid(block.image)}
                  alt={block.image.alt_text}
                  width="100%"
                  height="268px"
                  className="side-image "
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const IntroMoreInfoWithWaypoint = WithWaypoint(IntroMoreInfo)
