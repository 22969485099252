import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { siteMetaCtx } from "../store/siteMeta/context"
import { FullPage } from "../components/FullPage"
import { BuildingNavigator } from "../components/HomePage/BuildingNavigator"
import { IntroNewsWithWaypoint } from "../components/HomePage/IntroNews"
import { IntroAboutWithWaypoint } from "../components/HomePage/IntroAbout"
import { IntroContactWithWaypoint } from "../components/HomePage/IntroContact"
import { IntroMoreInfoWithWaypoint } from "../components/HomePage/IntroMoreInfo"

function getWaypointEffect() {
  return {
    effect: {
      activeStyle: {
        opacity: 1,
        transform: "translateY(0)",
        transition: "opacity 1.2s ease-in-out, transform .8s ease-in-out",
      },
      inactiveStyle: {
        opacity: 0,
        transform: "translateY(40px)",
      },
    },
  }
}

const IndexPage = ({ data, uri }) => {
  const {
    basic_info,
    full_page,
    building_navigator,
    section_news,
    section_about,
    section_contact,
    section_more_info,
  } = data.allWordpressPost.edges[0].node.acf

  return (
    <siteMetaCtx.Provider value={{ uri }}>
      <Layout>
        <SEO
          lang="zh-TW"
          title={basic_info.seo_title}
          description={basic_info.seo_description}
        ></SEO>
        <FullPage data={full_page} />
        <section id="body-section" className="position-relative">
          <div className="fixed-top body-img"></div>
          <BuildingNavigator data={building_navigator} />
          <IntroNewsWithWaypoint data={section_news} {...getWaypointEffect()} />
          <IntroAboutWithWaypoint
            data={section_about}
            {...getWaypointEffect()}
          />
          <IntroContactWithWaypoint
            data={section_contact}
            {...getWaypointEffect()}
          />
          <IntroMoreInfoWithWaypoint
            data={section_more_info}
            {...getWaypointEffect()}
          />
        </section>
      </Layout>
    </siteMetaCtx.Provider>
  )
}

export const query = graphql`
  fragment wp_img on wordpress__wp_media {
    alt_text
    localFile {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

  query {
    allWordpressPost(filter: { slug: { eq: "index" } }) {
      edges {
        node {
          id
          acf {
            basic_info {
              seo_title
              seo_description
            }
            full_page {
              title
              subtitle
              button_text
              button_link
              background_images {
                ...wp_img
              }
            }
            building_navigator {
              title
              subtitle
              link_url
            }
            section_news {
              title
              subtitle
              third_title
              paragraph
              button {
                text
                link_url
              }
              image {
                ...wp_img
              }
            }
            section_about {
              title
              subtitle
              third_title
              paragraph
              button {
                text
                link_url
              }
              image {
                ...wp_img
              }
            }
            section_contact {
              title
              subtitle
              third_title
              paragraph
              button {
                text
                link_url
              }
              image {
                ...wp_img
              }
            }
            section_more_info {
              title
              subtitle
              link_url
              image {
                ...wp_img
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
// {
//   allWordpressPost(filter: { slug: { eq: "index" } }) {
//     edges {
//       node {
//         id
//         acf {
//           full_page {
//             title
//             subtitle
//             button_text
//             background_images {
//               source_url
//               alt_text
//             }
//           }
//           building_navigator {
//             title
//             subtitle
//             link_url
//           }
//           section_news {
//             title
//             subtitle
//             third_title
//             paragraph
//             button {
//               text
//               link_url
//             }
//             image {
//               source_url
//               alt_text
//             }
//           }
//           section_about {
//             title
//             subtitle
//             third_title
//             paragraph
//             button {
//               text
//               link_url
//             }
//             image {
//               source_url
//               alt_text
//             }
//           }
//           section_contact {
//             title
//             subtitle
//             third_title
//             paragraph
//             button {
//               text
//               link_url
//             }
//             image {
//               source_url
//               alt_text
//             }
//           }
//           section_more_info {
//             title
//             subtitle
//             link_url
//             image {
//               source_url
//               alt_text
//             }
//           }
//         }
//       }
//     }
//   }
// }
