import React, { useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import Img from "gatsby-image"
import $ from "jquery"

import { getImgFluid } from "../utils/data"

export const FullPage = ({ data }) => {
  useEffect(() => {
    $("#scrollbtn").click(function() {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#body-section").offset().top,
        },
        1000
      )
    })
  }, [])
  return (
    <section className="fullpage">
      <div
        id="carouselExampleIndicators"
        className="carousel slide fullpage"
        data-ride="carousel"
        data-pause="false"
        data-interval="5000"
      >
        <div className="fullpage-frame">
          <div className="carousel-indicators carousel-indicators-custom m-0">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </div>
          <div className="fullpage-navigation-btn">
            <button
              type="button"
              id="scrollbtn"
              className="btn btn-link rounded-circle w-100 h-100 row justify-content-center arrow-btn"
            >
              <i className="material-icons align-self-center">
                keyboard_arrow_down
              </i>
            </button>
          </div>
        </div>

        <div className="carousel-inner w-100 h-100">
          {data.background_images.map((image, idx) => (
            <div
              className={`carousel-item ${idx === 0 ? "active" : ""}`}
              key={idx}
            >
              <Img
                fluid={getImgFluid(image)}
                alt={image.alt_text}
                fadeIn={false}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="fullpage-textarea">
        <div>
          <div className="row fullpage-title">
            <p className="mr-auto ml-auto p-0 fullpage-title-text">
              {data.title}
            </p>
          </div>
          <div className="row fullpage-subtitle">
            <div className="p-0 fullpage-subtitle-text">
              {ReactHtmlParser(data.subtitle)}
            </div>
          </div>
          <div className="row">
            <div className="col-24">
              <a href={data.button_link}>
                <button
                  type="button"
                  className="btn btn-outline-light fullpage-booking-btn"
                >
                  {data.button_text}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
